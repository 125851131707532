<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-profile">
          <Avatar />
          <div class="card-body">
            <!-- <h3 class="card-category text-gray">TELCO PAGOS DOC</h3> -->
            <h3 class="card-title fontF">Plazos de acreditación!</h3>
            <br /><br />

            <p class="card-description mx-5" align="left">
              ● Los plazos de acreditación de los Fondos Percibidos son
              establecidos a partir de la Fecha Informada de Pago y de acuerdo
              al medio de pago utilizado en la transacción.
            </p>
            <p class="card-description mx-5" align="left">
              ● Días hábiles son considerados de lunes a viernes, exceptuando
              feriados, feriados bancarios y cualquier otro evento de fuerza
              mayor.
            </p>
            <p class="card-description mx-5" align="left">
              ● Wee.ar podrá acreditar los Fondos Percibidos antes de este plazo
              en los casos que así considere adecuado.
            </p>

            <template>
              <v-data-table
                :headers="headers"
                :items="medios"
                :items-per-page="6"
                hide-default-footer
                class="elevation-1 my-5"
              ></v-data-table>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "./Avatar.vue";
export default {
  name: "PlazosAcreditacion",
  components: { Avatar },
  data() {
    return {
      headers: [
        { text: "MEDIO DE PAGO", value: "medio", sortable: false },
        { text: "PLAZO DE ACREDITACIÓN", value: "plazo", sortable: false },
      ],
      medios: [
        { medio: "DEBIN", plazo: "1 día hábil" },
        { medio: "QR", plazo: "1 dia hábil" },
        { medio: "Tarjeta de Débito", plazo: "2 días hábiles" },
        {
          medio: "Tarjeta de Crédito - todas excepto Amex - en 1 Pago",
          plazo: "18 días hábiles",
        },
        {
          medio: "Tarjeta de Crédito - todas excepto Amex - en Cuotas",
          plazo: "2 días hábiles",
        },
        { medio: "Pagos Off Line", plazo: "7 días hábiles" },
      ],
    };
  },
};
</script>
<style scoped>
.fontF {
  font-family: "Montserrat", sans-serif !important;
  font-size: 20px !important;
}
</style>
